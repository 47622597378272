import React, { useState } from "react"
import { Link } from "gatsby"
import Payhere from "payhere-embed-sdk/dist/react"

import { routes } from "../utils/routes"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const DonateNow: React.FC<PageProps> = ({ data }) => {
  const [showPayhere, setShowPayhere] = useState<string | null>(null)

  return (
    <Layout
      sectionOne={
        <>
          <StaticImage
            src="../../static/images/2024/02/donate-header.jpg"
            className="absolute inset-0 -z-[1]"
            alt="Donate"
          />
          <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
            <h1 className="pb-2 md:pb-8 responsive-heading uppercase">
              Donate Now
            </h1>
            <div className="pt-8 flex justify-center">
              <img src="/images/down.png" alt="Down arrow" />
            </div>
          </div>
        </>
      }
    >
      <SEO title="Donate Now" />
      <div className="container py-20">
        <div className="grid gap-8 md:grid-cols-2 pb-14">
          <a
            className="btn btn-secondary btn-lg font-medium capitalize"
            href="#"
            onClick={(e) => {
              e.preventDefault()
              setShowPayhere(
                "https://app.payhere.co/rbai-foundation/regular-donation"
              )
            }}
          >
            Setup a Regular Gift
          </a>
          <a
            className="btn btn-secondary btn-lg font-medium capitalize"
            href="#"
            onClick={(e) => {
              e.preventDefault()
              setShowPayhere(
                "https://app.payhere.co/rbai-donations/donation-one-off"
              )
            }}
          >
            Give One-Off Gift Online
          </a>
          {/*
            <Link className="button d-block my-3" to={routes.pledge()}>
              Sponsor A Personalised Paviour
            </Link>
          */}
        </div>
        <div className="flex justify-center">
          <div className="flex flex-col gap-4 max-w-[700px]">
            <p>Other ways you can donate to RBAI:</p>
            <p>
              <strong>By Post</strong> – you can send a one-off gift or set up a
              regular gift by downloading a 
              <a
                className="link underline hover:no-underline"
                href="/docs/donate-form-0323.pdf"
                target="_blank"
              >
                donation form here
              </a>{" "}
              and returning to the address below. For one-off gifts, all cheques
              should be payable to  ‘The RBAI Foundation.
            </p>
            <p>
              <strong>Legacies</strong> – find out more information about
              leaving a legacy gift{" "}
              <Link
                className="link underline hover:no-underline"
                to={routes.legacies()}
              >
                here.
              </Link>
            </p>
            <p>
              <strong>Overseas Donors</strong> – whether a cash gift or shares,
              in first instance please contact Peter Anderson, RBAI Foundation
              Director and we will provide the appropriate paperwork and ensure
              you can give in the most tax efficient manner.
            </p>
            <p>
              <strong>Donate Stocks & Shares</strong> – this offers the most
              generous tax relief available to donors, combining relief on
              income and capital gains tax. Share gifts qualify for tax relief
              equal to the market value of the shares on the day the gift is
              made, including associated costs such as broker fees. In the first
              instance, please contact Peter Anderson, RBAI Foundation Director
              for more information.
            </p>
            <p>
              <strong>Donate an Insurance Policy –</strong> contribute an
              existing life insurance policy, or designate the School as the
              owner and beneficiary of a new policy. In either case, the value
              of the existing policy (or the amount of the donors investment, if
              less) or the donated annual premium payments are deductible from
              your tax liabilities as charitable contributions. In first
              instance please contact Peter Anderson, RBAI Foundation Director.
            </p>
            <p>
              <strong>Gifts In Kind</strong> – this includes donations of goods
              and services rather than monetary donations. Whether a camera for
              the art department or property, antiques, art or professional
              services offered to the School free of charge, please contact
              Peter Anderson, RBAI Foundation Director in first instance.
            </p>
            <p>
              As with donated shares, gifts in kind may be sold on by the School
              to help achieve our fundraising goals.
            </p>
            <p>
              For any enquiries, please contact:
              <br />
              Peter Anderson, RBAI Foundation Director
              <br />
              Email:{" "}
              <a
                className="link underline hover:no-underline"
                href="mailto:p.anderson@rbai.org.uk"
              >
                p.anderson@rbai.org.uk
              </a>
              <br />
              Tel: +44 (0) 28 9024 0461
            </p>
            <p>
              Please send all correspondence to:
              <br />
              The RBAI Foundation
              <br />
              The Royal Belfast Academical Institution
              <br />
              College Square East
              <br />
              Belfast
              <br />
              BT1 6DL
            </p>
          </div>
        </div>
      </div>

      <Payhere
        selector="#payhere-modal"
        embedURL={showPayhere}
        open={showPayhere !== null}
        onClose={() => {
          setShowPayhere(null)
        }}
        onSuccess={() => {}}
        onFailure={() => {}}
      />
    </Layout>
  )
}

export default DonateNow
